import styles from './transaction-risk-categories.module.scss';
import CustomerDetailsBox from '../../pages/customer-details/CustomerDetailsBox';
import { ReactComponent as NineDotsIcon } from '../../assets/images/svg/nine-dots.svg';
import CategoryInfo from './CategoryInfo';
import { useContext, useMemo } from 'react';
import {
  misttrackCategories,
  misttrackCategoryNames,
  misttrackLabelTypeToCategory,
  misttrackTypes
} from '../../utils/misttrack';
import { UserContext } from '../../context/user/UserContext';


const NO_INTERACTION = 'No interaction';

const TransactionRiskCategories = ({ riskResult, chain, connectedAddress, txsLoaderFactory, temporaryUserJwt }) => {
  const { getConfig } = useContext(UserContext);

  const interactionDetails = useMemo(() => {
    const riskDetails = riskResult?.risk_detail ?? [];
    const labelByCategory = riskDetails.toReversed().reduce((acc, entry) => {
      if (entry?.type && entry?.label && entry.type in misttrackLabelTypeToCategory) {
        acc[misttrackLabelTypeToCategory[entry.type]] = entry.label;
      }
      return acc;
    }, {});
    const categories = riskResult?.detail_list ?? [];
    const customLabels = {};
    categories.forEach(category => customLabels[category] = 'Interaction');
    Object.entries(labelByCategory).forEach(([category, label]) => customLabels[category] = label);
    const isOfacSanctioned = riskDetails.map(entry => entry?.label).some(label => label.includes('OFAC'));
    if (isOfacSanctioned && !categories.includes(misttrackCategoryNames.SANCTIONED_ENTITY)) {
      categories.push(misttrackCategoryNames.SANCTIONED_ENTITY);
    }
    if (isOfacSanctioned) {
      customLabels[misttrackCategoryNames.SANCTIONED_ENTITY] = 'Interaction (OFAC)';
      customLabels[misttrackCategoryNames.INTERACT_WITH_MALICIOUS_ADDRESS] = 'Interaction';
    }
    return customLabels;
  }, [riskResult]);

  const interactedWithCategories = useMemo(() => Object.keys(interactionDetails), [riskResult]);

  const determineInteraction = (category) => {
    const customLabel = interactionDetails?.[category];
    if (customLabel) {
      return {
        text: customLabel,
        isSafe: false,
      };
    }
    return {
      text: NO_INTERACTION,
      isSafe: true,
    };
  };
  const interactedWithCategoriesSorted = misttrackCategories.filter(category => interactedWithCategories.includes(category));
  const notInteractedWithCategoriesSorted = misttrackCategories.filter(category => !interactedWithCategories.includes(category));
  const orderedCategories = useMemo(() => {
    return [...interactedWithCategoriesSorted, ...notInteractedWithCategoriesSorted].filter(category => category);
  }, [interactedWithCategories]);

  const expandableCategories = [
    misttrackCategoryNames.SANCTIONED_ENTITY,
    misttrackCategoryNames.MALICIOUS_ADDRESS,
    misttrackCategoryNames.SUSPECTED_MALICIOUS_ADDRESS,
    misttrackCategoryNames.HIGH_RISK_TAG_ADDRESS,
    misttrackCategoryNames.MEDIUM_RISK_TAG_ADDRESS,
  ];

  const getCategoryMarkedAddresses = (category) => {
    const markedAddresses = [];
    for (const addressInfo of (riskResult?.risk_detail ?? [])) {
      const labelLower = (addressInfo.label ?? '').toLowerCase();
      const isOfacSanctioned = labelLower.includes('ofac');

      // SANCTIONED_ENTITY has type "malicious" and label "OFAC Sanctions"
      let pushThisAddress = false;
      if (category === misttrackCategoryNames.SANCTIONED_ENTITY && isOfacSanctioned) {
        markedAddresses.push(addressInfo.address);
      } else if (category === misttrackCategoryNames.MALICIOUS_ADDRESS) { // Put !isOfacSanctioned here if wanted
        if (addressInfo.type === misttrackTypes.MALICIOUS) {
          pushThisAddress = true;
        }
      } else if (category === misttrackCategoryNames.SUSPECTED_MALICIOUS_ADDRESS) {
        if (addressInfo.type === misttrackTypes.SUSPECTED_MALICIOUS) {
          pushThisAddress = true;
        }
      } else if (category === misttrackCategoryNames.HIGH_RISK_TAG_ADDRESS) {
        if (addressInfo.type === misttrackTypes.HIGH_RISK) {
          pushThisAddress = true;
        }
      } else if (category === misttrackCategoryNames.MEDIUM_RISK_TAG_ADDRESS) {
        if (addressInfo.type === misttrackTypes.MEDIUM_RISK) {
          pushThisAddress = true;
        }
      }
      // Add other categories here if needed, but you need a way determine if addressInfo corresponds to that category

      if (pushThisAddress) {
        markedAddresses.push(addressInfo.address);
      }
    }
    return markedAddresses;
  };

  return <CustomerDetailsBox title='Transactions risk categories' icon={<NineDotsIcon />}>
    <div className={styles.categoriesContainer}>
      {orderedCategories.map((category, index) => {
        const interaction = determineInteraction(category);
        const expandable = getConfig('quickcheck_suspicious_txs') && expandableCategories.includes(category) && interaction.text !== NO_INTERACTION;
        return <CategoryInfo
          title={category}
          valueInfo={interaction}
          withBorder={index < misttrackCategories.length - 2}
          key={category}
          temporaryUserJwt={temporaryUserJwt}
          connectedAddress={connectedAddress}
          thisCategoryMarkedAddresses={getCategoryMarkedAddresses(category)}
          chain={chain}
          expandable={expandable}
          txsLoaderFactory={txsLoaderFactory}
        />;
      })}
    </div>
  </CustomerDetailsBox>;
};

export default TransactionRiskCategories;
