import styles from './casp-vasp-list.module.scss';
import globalStyles from '../../pages/customer-details/style.module.scss';
import Header from '../../components/header/Header';
import routes from '../../routes/routes';
import CustomerDetailsBox from '../customer-details/CustomerDetailsBox';
import { ReactComponent as EarthIcon } from 'assets/images/svg/earth-icon.svg';
import CaspVaspTableRow from '../../components/casp-vasp/CaspVaspTableRow';
import { clsx } from 'clsx';
import CaspVaspTableSubheader from '../../components/casp-vasp/CaspVaspTableSubheader';
import CaspVaspTableRowsSameCategory from '../../components/casp-vasp/CaspVaspTableRowsSameCategory';

const tableDataNoCategory = [
  {
    'register': 'Austria',
    'vasp': 'https://www.fma.gv.at/en/search-company-database/',
    'casp': 'https://www.fma.gv.at/en/search-company-database/',
    'transitionPeriod': '12',
    'details': ''
  },
  {
    'register': 'Belgium',
    'vasp': 'https://www.fsma.be/en/virtual-asset-service-provider-vasp',
    'casp': '',
    'transitionPeriod': 'TBA',
    'details': ''
  },
  {
    'register': 'Bulgaria',
    'vasp': 'https://nra.bg/wps/portal/nra/registers-i-spisuci/registers/page.virtualni.valuti',
    'casp': '',
    'transitionPeriod': '12',
    'details': ''
  },
  {
    'register': 'Croatia',
    'vasp': 'https://www.hanfa.hr/registri/?Virtualne%20valute',
    'casp': 'https://www.hanfa.hr/registri/virtualne-valute/popis-drustava-certificiranih-po-mica-uredbi/',
    'transitionPeriod': '18',
    'details': ''
  },
  {
    'register': 'Cyprus',
    'vasp': 'https://www.cysec.gov.cy/en-GB/entities/crypto-asset-services-providers-casps/',
    'casp': '',
    'transitionPeriod': '18',
    'details': 'Applicable for VASP > CySEC is the anti-money laundering and counter-terrorist financing (AML/CFT) supervisor for crypto-asset operations undertaken in or from Cyprus. If you are a Crypto Asset Services Provider (CASP), within the meaning of the Prevention and Suppression of Money Laundering and Terrorist Financing Law that provides services in or from Cyprus, you must formally register with the CySEC.'
  },
  {
    'register': 'Czechia',
    'vasp': 'https://www.rzp.cz/verejne-udaje/cs/udaje/vyber-subjektu',
    'casp': 'https://jerrs.cnb.cz/apljerrsdad/JERRS.WEB07.INTRO_PAGE',
    'transitionPeriod': '18',
    'details': 'To be able to benefit from the grandfathering period, applicant CASPs must apply before 31 July 2025.'
  },
  {
    'register': 'Denmark',
    'vasp': 'https://virksomhedsregister.finanstilsynet.dk/index-en.html',
    'casp': 'https://virksomhedsregister.finanstilsynet.dk/index-en.html',
    'transitionPeriod': '18',
    'details': 'To be able to benefit from the grandfathering period, applicant CASPs must apply before 30 December 2024.'
  },
  {
    'register': 'Estonia',
    'vasp': 'https://mtr.ttja.ee/tegevusluba?m=97',
    'casp': 'https://www.fi.ee/en/supervised-entities?closed=1',
    'transitionPeriod': '18',
    'details': ''
  },
  {
    'register': 'Finland',
    'vasp': 'https://www.finanssivalvonta.fi/en/registers/supervised-entities/',
    'casp': '',
    'transitionPeriod': '6',
    'details': ''
  },
  {
    'register': 'France',
    'vasp': 'https://www.amf-france.org/en/professionals/fintech/my-relations-amf/digital-asset-service-provider-dasp/obtain-dasp-authorisation#List_of_DASPs_registered__licensed_with_the_AMF',
    'casp': '',
    'transitionPeriod': '18',
    'details': 'First link includes delisted DASP'
  },
  {
    'register': '',
    'vasp': 'https://www.amf-france.org/en/warnings/white-lists?page=0&conditions%5Bcategorie%5D=612',
    'casp': '',
    'transitionPeriod': '',
    'details': ''
  },
  {
    'register': 'Germany',
    'vasp': 'https://portal.mvp.bafin.de/database/InstInfo/?locale=en_GB',
    'casp': '',
    'transitionPeriod': 'TBA (?12)',
    'details': ''
  },
  {
    'register': 'Greece',
    'vasp': 'http://www.hcmc.gr/en_US/web/portal/mlaundering1',
    'casp': '',
    'transitionPeriod': '12',
    'details': '2 registers are under headline Register of Service Providers (VASPs)'
  },
  {
    'register': 'Hungary',
    'vasp': '?',
    'casp': '?https://intezmenykereso.mnb.hu/en/Home/Index',
    'transitionPeriod': '6',
    'details': ''
  },
  {
    'register': 'Ireland',
    'vasp': 'https://registers.centralbank.ie/',
    'casp': '',
    'transitionPeriod': '12',
    'details': ''
  },
  {
    'register': 'Italy',
    'vasp': 'http://www.organismo-am.it/elenchi-registri/operatori_valute_virtuali/index.html',
    'casp': '??https://www.consob.it/web/consob-and-its-activities/participants-and-markets',
    'transitionPeriod': '12',
    'details': 'Entities registered as VASPs in the Italian AML/TF register must file their application for MICA authorisation  within six months to benefit from the grandfathering period.'
  },
  {
    'register': 'Latvia',
    'vasp': '',
    'casp': '??https://uzraudziba.bank.lv/en/market/',
    'transitionPeriod': '6',
    'details': ''
  },
  {
    'register': 'Lithuania',
    'vasp': 'https://www.registrucentras.lt/p/1025#20230201',
    'casp': 'https://www.lb.lt/en/sfi-financial-market-participants',
    'transitionPeriod': '5',
    'details': ''
  },
  {
    'register': '',
    'vasp': 'https://www.registrucentras.lt/jar/sarasai/vvko.php',
    'casp': 'https://www.lb.lt/en/the-list-of-entities-without-the-right-to-provide-financial-including-investment-services-in-lithuania',
    'transitionPeriod': '',
    'details': ''
  },
  {
    'register': '',
    'vasp': 'https://www.registrucentras.lt/jar/sarasai/dvvpo.php',
    'casp': 'https://www.lb.lt/illegalwww',
    'transitionPeriod': '',
    'details': ''
  },
  {
    'register': 'Luxembourg',
    'vasp': 'https://edesk.apps.cssf.lu/search-entities/search',
    'casp': '',
    'transitionPeriod': '18',
    'details': ''
  },
  {
    'register': 'Malta',
    'vasp': 'https://www.mfsa.mt/financial-services-register/',
    'casp': 'https://www.mfsa.mt/financial-services-register/',
    'transitionPeriod': '10',
    'details': 'There is VFA and MiCA sector'
  },
  {
    'register': '',
    'vasp': 'https://www.mfsa.mt/news-item/warning-to-the-public-regarding-unlicensed-vfa-companies/',
    'casp': '',
    'transitionPeriod': '',
    'details': ''
  },
  {
    'register': 'Nerherland',
    'vasp': 'https://www.dnb.nl/en/public-register/?p=1&l=20&rc=V1dGVEFD',
    'casp': 'https://www.afm.nl/en/sector/registers/vergunningenregisters/cryptopartijen',
    'transitionPeriod': '6',
    'details': ''
  },
  {
    'register': 'Poland',
    'vasp': 'https://www.slaskie.kas.gov.pl/izba-administracji-skarbowej-w-katowicach/zalatwianie-spraw/rejestr-dzialalnosci-w-zakresie-walut-wirtualnych/-/asset_publisher/R7Yl/content/rejestr-dzialalnosci-w-zakresie-walut-wirtualnych',
    'casp': '??https://www.knf.gov.pl/podmioty/wyszukiwarka_podmiotow',
    'transitionPeriod': '6',
    'details': 'On January 1st, 2026 the VASP register will cease to exist.'
  },
  {
    'register': 'Portugal',
    'vasp': 'https://www.bportugal.pt/sites/default/files/documents/2024-01/lista_entidades_ativos_virtuais_en.pdf',
    'casp': '??https://www.bportugal.pt/en/page/list-authorised-institutions',
    'transitionPeriod': 'TBA',
    'details': ''
  },
  {
    'register': '',
    'vasp': 'https://www.bportugal.pt/en/page/registration-virtual-assets-service-providers',
    'casp': '',
    'transitionPeriod': '',
    'details': ''
  },
  {
    'register': 'Romania',
    'vasp': '-',
    'casp': '',
    'transitionPeriod': '18',
    'details': ''
  }, {
    'register': 'Slovenia',
    'vasp': 'https://www.gov.si/teme/preprecevanje-pranja-denarja/',
    'casp': '-',
    'transitionPeriod': '6',
    'details': 'See under Seznam registriranih ponudnikov storitev virtualnih valut'
  },
  {
    'register': 'Slovakia',
    'vasp': 'https://www.orsr.sk/',
    'casp': 'https://subjekty.nbs.sk/?s=1414',
    'transitionPeriod': '12',
    'details': ''
  },
  {
    'register': 'Spain',
    'vasp': 'https://www.bde.es/wbe/en/entidades-profesionales/operativa-gestiones/registros/registros-entidades/',
    'casp': '',
    'transitionPeriod': '12',
    'details': ''
  },
  {
    'register': 'Sweden',
    'vasp': '?https://fi.se/en/our-registers/company-register/',
    'casp': '',
    'transitionPeriod': '9',
    'details': ''
  },
  {
    'register': '',
    'vasp': 'https://fi.se/en/our-registers/investor-alerts/',
    'casp': '',
    'transitionPeriod': '',
    'details': 'Includes warnings collected from outside of the SE market.'
  },
];

const tableDataEeaCountries = [
  {
    'register': 'Island',
    'vasp': 'https://en.fme.is/supervision/supervised-entities/',
    'casp': '',
    'transitionPeriod': '18',
    'details': ''
  },
  {
    'register': 'Lichtenstein',
    'vasp': 'https://register.fma-li.li/search',
    'casp': '',
    'transitionPeriod': '12',
    'details': ''
  },
  {
    'register': 'Norway',
    'vasp': 'https://www.finanstilsynet.no/en/finanstilsynets-registry/',
    'casp': '',
    'transitionPeriod': 'TBA',
    'details': 'Comapny services / Virtual currency service providers. The Norwegian Ministry of Finance assigned the task of assessing the Norwegian implementation of MiCA to the Norwegian Financial Supervisory Authority, setting a deadline of 1 March 2024.'
  },
];

const tableDataOtherCountries = [
  {
    'register': 'Andora',
    'vasp': 'https://www.afa.ad/en/entitats-supervisades/digital-assets/participants-actors-relacionats-amb-els-actius-digitals/register-of-autorised-entities',
    'casp': '',
    'transitionPeriod': '',
    'details': ''
  },
  {
    'register': 'Georgia',
    'vasp': 'https://nbg.gov.ge/en/page/virtual-asset-service-providers-vasps',
    'casp': '',
    'transitionPeriod': '',
    'details': ''
  },
  {
    'register': 'Gibraltar',
    'vasp': 'https://www.fsc.gi/regulated-entities',
    'casp': '',
    'transitionPeriod': '',
    'details': ''
  },
  {
    'register': 'Jersey',
    'vasp': 'https://www.jerseyfsc.org/industry/vasp-entities/',
    'casp': '',
    'transitionPeriod': '',
    'details': ''
  },
  {
    'register': 'Serbia',
    'vasp': 'https://nbs.rs/en/ciljevi-i-funkcije/nadzor-nad-finansijskim-institucijama/digital-imo/reg_di/',
    'casp': '',
    'transitionPeriod': '',
    'details': ''
  },
  {
    'register': 'United Kingdom',
    'vasp': 'https://register.fca.org.uk/s/search?predefined=CA',
    'casp': '',
    'transitionPeriod': '',
    'details': ''
  },
  {
    'register': '',
    'vasp': 'https://register.fca.org.uk/s/',
    'casp': '',
    'transitionPeriod': '',
    'details': ''
  },
  {
    'register': 'Anguilla',
    'vasp': 'https://fsc.org.ai/utilitytospre.php',
    'casp': '',
    'transitionPeriod': '',
    'details': ''
  },
  {
    'register': '',
    'vasp': 'https://fsc.org.ai/unlicensedcm.php',
    'casp': '',
    'transitionPeriod': '',
    'details': ''
  },
  {
    'register': 'British Virgin Islands',
    'vasp': 'https://www.bvifsc.vg/regulated-entities-vasp',
    'casp': '',
    'transitionPeriod': '',
    'details': ''
  },
  {
    'register': 'Cayman Islands',
    'vasp': 'https://www.cima.ky/search-entities-cima/',
    'casp': '',
    'transitionPeriod': '',
    'details': ''
  },
];

const CaspVaspList = () => {
  return <div className='page-content-container'>
    <div className={clsx(styles.container, globalStyles.customers)}>
      <Header headerTitle='Knowledge hub / List of CASP and VASP' withBackButton backRoute={routes.home.knowledgeHub.dashboard}/>

      <CustomerDetailsBox
        title='List of CASP / VASP'
        icon={<EarthIcon/>}
      >
        <div className={styles.caspVaspTable}>
          <div className={styles.tableHeader}>
            <span></span>
            <span>EU ESMA Register</span>
            <span>VASP</span>
            <span>CASP</span>
            <span>Transition period</span>
          </div>
          <div className={styles.tableBody}>
            <CaspVaspTableRowsSameCategory entries={tableDataNoCategory} category='no-category' />

            <CaspVaspTableSubheader title='EEA countries'/>
            <div className={styles.rowDivider}></div>

            <CaspVaspTableRowsSameCategory entries={tableDataEeaCountries} category='eea-countries' />

            <CaspVaspTableSubheader title='Other countries'/>
            <div className={styles.rowDivider}></div>

            <CaspVaspTableRowsSameCategory entries={tableDataOtherCountries} category='other-countries' />
          </div>
        </div>
      </CustomerDetailsBox>
    </div>
  </div>;
};

export default CaspVaspList;
