import axios from 'axios';
import getExchangeRates from '../../../api/exchange-rates';
import { unique, valueExists } from '../../../../utils/common';
import { filterTypes } from '../../../../components/filters/ConsolidatedTransactionsFilters';
import { addMisttrackRiskToTxs } from '../../../../utils/misttrack';
import { axiosErrorHandler } from '../../../api/axios';
import { toast } from 'react-hot-toast';
import { handleQuickRiskError } from '../../../../utils/quick-risk';
import * as Sentry from '@sentry/react';

const USDT_CONTRACT_TYPE = 31;
const STAKE_TRON_CONTRACT_TYPE = 54;
const DELEGATE_ENERGY_CONTRACT_TYPE = 57;
const RECLAIM_ENERGY_CONTRACT_TYPE = 58;
const KNOWN_CONTRACT_TYPES = [
  DELEGATE_ENERGY_CONTRACT_TYPE,
  RECLAIM_ENERGY_CONTRACT_TYPE,
  STAKE_TRON_CONTRACT_TYPE,
  USDT_CONTRACT_TYPE
];

const STAKE_TRON_TYPE = 'stake';
const DELEGATE_RESOURCES_TYPE = 'delegate resources';
const RECLAIM_RESOURCES_TYPE = 'reclaim resources';

const USDT_CONTRACT_ADDRESS = 'TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t';

const getTronTxsLoader = ({
  address,
  accountId,
  withMisttrackRisks = true,
  currency = 'usd',
  pageSize = 50,
  apiUrl,
  userJwt,
  fromAddress = undefined,
  toAddress = undefined,
  externalApiKey = undefined,  // Tronscan API key, but general name to conform to loaders interface (virtual)
}) => {
  let offset = 0;
  let nextPageExists = true;
  const trxCurrencyPair = `trx/${currency.toLowerCase()}`;
  const usdtCurrencyPair = `usdt/${currency.toLowerCase()}`;
  const exchangeRates = {};

  const loadNextPage = async (filters) => {
    if (Object.keys(exchangeRates).length === 0) {
      const rates = await getExchangeRates([trxCurrencyPair, usdtCurrencyPair], apiUrl, userJwt);
      exchangeRates['trx'] = rates?.[trxCurrencyPair] ?? 0;
      exchangeRates['usdt'] = rates?.[usdtCurrencyPair] ?? 0;
    }

    const params = {
      sort: '-timestamp',
      count: true,
      start: offset,
      limit: pageSize,
      address,
    };
    const headers = {};

    // These params require api key, but we don't add it to other requests to not waste credits
    if (fromAddress !== undefined || toAddress !== undefined) {
      if (!externalApiKey) {
        toast.error('Missing Tronscan API key, please try again later');
        Sentry.captureException(Error('Missing Tronscan API key'));
      } else {
        headers['TRON-PRO-API-KEY'] = externalApiKey;
      }
    }
    if (fromAddress) {
      params.fromAddress = fromAddress;
    }
    if (toAddress) {
      params.toAddress = toAddress;
    }

    if (filters) {
      const {
        accountsFilterParam,
        digitalAssetsFilterParam,
      } = filters;

      if (valueExists(accountsFilterParam)) {
        if (!accountsFilterParam.split(',').includes(accountId)) {
          nextPageExists = false;
          return {
            nextPage: [],
            loadError: undefined,
          };
        }
      }
      if (valueExists(digitalAssetsFilterParam)) {
        if (!digitalAssetsFilterParam.split(',').includes('TRX')) {
          nextPageExists = false;
          return {
            nextPage: [],
            loadError: undefined,
          };
        }
      }
    }

    const url = 'https://apilist.tronscanapi.com/api/transaction';
    let data = [];
    try {
      const response = await axios.get(
        url,
        { params, headers }
      );
      data = response?.data?.data ?? [];
    } catch (err) {
      toast.error(axiosErrorHandler(err, 'Error getting Tron transactions from explorer, data might be incomplete'));
    }

    if (data.length < pageSize) {
      nextPageExists = false;
    }
    offset += data.length;
    const accountTransactions = data.map(tronscanTx => transformToAccountTx(tronscanTx, address, accountId, exchangeRates, currency));

    if (!withMisttrackRisks) {
      return {
        nextPage: accountTransactions,
        loadError: undefined,
      };
    }

    try {
      const quickRiskResponse = await axios.get(
        '/v1/quick_risk',
        {
          baseURL: apiUrl,
          headers: {
            'Authorization': `Bearer ${userJwt}`,
          },
          params: {
            chain: 'trx',
            address: address,
            metrics: 'riskResult',
          },
        }
      );
      const riskDetail = quickRiskResponse?.data?.risk_result?.risk_detail ?? [];
      const txsWithCustomRisk = addMisttrackRiskToTxs(accountTransactions, riskDetail);
      return {
        nextPage: txsWithCustomRisk,
        loadError: undefined,
      };
    } catch (err) {
      handleQuickRiskError(err);
      return {
        nextPage: [],
        loadError: undefined,
      };
    }
  };

  const hasNextPage = () => {
    return nextPageExists;
  };

  const reset = () => {
    offset = 0;
    nextPageExists = true;
  };

  const getAllowedFilters = () => {
    return [filterTypes.ACCOUNTS, filterTypes.ASSETS];
  };

  return { loadNextPage, hasNextPage, reset, getAllowedFilters };
};

const transformToAccountTx = (tronscanTx, originatorAddress, accountId, exchangeRates, fiatCurrency) => {
  const decimals = tronscanTx?.tokenInfo?.tokenDecimal ?? 6;
  let value = tronscanTx?.contractData?.amount ?? 0;
  let valueDecimalized = value / Math.pow(10, decimals);
  let convertedValue = valueDecimalized * exchangeRates['trx'];
  const tokenId = tronscanTx?.tokenInfo?.tokenId ?? 'TRON:';
  let currency = (tokenId === '_' ? 'trx' : `TRON:${String(tokenId)}`).toUpperCase();
  let currencyName = tronscanTx?.tokenInfo?.tokenName ?? '?';
  let currencySymbol = (tronscanTx?.tokenInfo?.tokenAbbr ?? '?').toUpperCase();
  let ownerAddress = tronscanTx.ownerAddress;
  let toAddress = tronscanTx.toAddress;
  let ownerTag = tronscanTx?.ownerAddressTag;
  let toTag = tronscanTx?.toAddressTag;

  let type = 'transfer';
  if (value > 0) {
    if (originatorAddress === tronscanTx.ownerAddress && originatorAddress !== tronscanTx.toAddress) {
      type = 'crypto withdrawal';
    } else if (originatorAddress !== tronscanTx.ownerAddress && originatorAddress === tronscanTx.toAddress) {
      type = 'crypto deposit';
    }
  }

  if (tronscanTx?.contractData?.amount === undefined) {
    // For BANDWIDTH and ENERGY delegations and USDT
    if (KNOWN_CONTRACT_TYPES.includes(tronscanTx?.contractType)) {
      valueDecimalized = 1;
      value = 1000000;
      currency = `TRON:${tronscanTx.contractData?.resource}`;
      currencyName = String(tronscanTx.contractData?.resource);
      currencySymbol = String(tronscanTx.contractData?.resource).toUpperCase();
      if (tronscanTx.contractType === RECLAIM_ENERGY_CONTRACT_TYPE) {
        type = RECLAIM_RESOURCES_TYPE;
        [ownerAddress, toAddress] = [toAddress, ownerAddress];
        [ownerTag, toTag] = [toTag, ownerTag];
      } else if (tronscanTx.contractType === DELEGATE_ENERGY_CONTRACT_TYPE) {
        type = DELEGATE_RESOURCES_TYPE;
      } else if (tronscanTx.contractType === STAKE_TRON_CONTRACT_TYPE) {
        type = STAKE_TRON_TYPE;
        currency = 'TRX';
        currencyName = 'TRX';
        currencySymbol = 'TRX';
        toAddress = ownerAddress;
        toTag = ownerTag;
        value = (tronscanTx.contractData?.frozen_balance ?? 0);
        valueDecimalized = value / 1000000;
        convertedValue = valueDecimalized * exchangeRates['trx'];
      } else if (tronscanTx?.trigger_info && String(tronscanTx?.trigger_info?.method).includes('transfer')) {
        if (tronscanTx.trigger_info.contract_address === USDT_CONTRACT_ADDRESS) {
          value = Number(tronscanTx.trigger_info?.parameter?._value ?? 0);
          valueDecimalized = value / 1000000; // Appropriate for USDT
          convertedValue = valueDecimalized * exchangeRates['usdt'];
          toAddress = tronscanTx.trigger_info?.parameter?._to;
          currency = `TRON:${USDT_CONTRACT_ADDRESS}`;
          currencyName = 'USDT';
          currencySymbol = 'USDT';
        }
      }
    }
  }

  // Clear unwanted contract interactions
  if (currencyName === String(undefined)) {
    value = 0;
    valueDecimalized = 0;
  }

  const formatCurrencyValue = (value, decimals, currency, name, symbol) => {
    return {
      value: value / Math.pow(10, decimals),
      currency: currency,
      currency_name: name,
      currency_symbol: symbol
    };
  };

  const input = {
    address: ownerAddress,
    currency: currency,
    currency_name: currencyName,
    currency_symbol: currencySymbol,
    value: valueDecimalized,
    owned: ownerAddress === originatorAddress,
    fee: false,
    categories: [],
  };
  if (ownerTag) {
    input.category = ownerTag;
    input.categories = [ownerTag];
  }

  const output = {
    address: toAddress,
    currency: currency,
    currency_name: currencyName,
    currency_symbol: currencySymbol,
    value: valueDecimalized,
    owned: toAddress === originatorAddress,
    fee: false,
    categories: [],
  };
  if (toTag) {
    output.category = toTag;
    output.categories = [toTag];
  }

  const feeInfo = formatCurrencyValue(
    tronscanTx?.cost?.net_fee ?? 0,
    6,
    'trx',
    'trx',
    'trx',
  );

  if ([`TRON:${USDT_CONTRACT_ADDRESS}`.toLowerCase(), 'trx'].includes(currency.toLowerCase())) {
    input['converted_value'] = convertedValue;
    input['converted_currency'] = fiatCurrency;
    output['converted_value'] = convertedValue;
    output['converted_currency'] = fiatCurrency;
    feeInfo['converted_value'] = feeInfo.value * exchangeRates[currency.toLowerCase() === 'trx' ? 'trx' : 'usdt'];
    feeInfo['converted_currency'] = fiatCurrency;
  }

  const amount = formatCurrencyValue(
    value,
    decimals,
    currency,
    currencyName,
    currencySymbol,
  );
  const transformedTx = {
    transaction_id: tronscanTx.hash,
    account_id: accountId,
    type: type,
    amount: [amount],
    total: [amount],
    fee: feeInfo,
    created_at: new Date(tronscanTx.timestamp).toISOString(),
    inputs: [input].filter(input => input.value > 0),
    outputs: [output].filter(output => output.value > 0),
    categories: unique([ownerTag, toTag].filter(tag => tag)),
    hideValue: [DELEGATE_RESOURCES_TYPE, RECLAIM_RESOURCES_TYPE].includes(type),
  };
  return transformedTx;
};

export default getTronTxsLoader;
