const appRoutes = {
  auth: {
    login: '/login',
    signUp: '/sign-up',
    verifyEmail: '/sign-up/verify-email',
    confirmEmail: '/sign-up/confirm-email',
    resetPassword: '/reset-password',
    confirmNewPassword: '/reset-password/confirm-new-password',
  },
  home: {
    dashboard: '/',
    pageNotReady: '/page-in-development',
    myProjects: '/my-projects',
    myAccount: '/my-account',
    analytics: '/my-projects/:id/analytics',
    clients: '/my-projects/:id/clients',
    widgets: '/my-projects/:id/widgets',
    deposits: '/my-projects/:id/deposits',
    payouts: '/my-projects/:id/binance-pay',
    apiKeys: '/my-projects/:id/api-keys',
    apiKeysPublic: '/my-projects/:id/api-keys-public',
    settings: '/my-projects/:id/settings',
    depositsAdmin: '/my-projects/:id/deposits-admin',
    onboarding: '/complete-onboarding',
    widgetDetails: '/my-projects/:id/details',
    billing: '/billing',
    billingManagePlan: '/billing/manage-plan',
    billingApiUsage: '/billing/api-usage',
    customerDetails: '/my-projects/:id/clients/customer/:user_id',
    usersManagement: '/users-management',
    explorer: '/explorer',
    amlQuickCheck: '/aml-quick-check',
    companySettings: '/settings',
    knowledgeHub: {
      dashboard: '/knowledge-hub',
      proofOfFunds: '/knowledge-hub/proof-of-funds',
      caspVaspList: '/knowledge-hub/casp-vasp-list',
    },
  },
  external: {
    main: 'https://www.blockmate.io/',
    contact: 'https://www.blockmate.io/contact',
    termsAndCondition: 'https://www.blockmate.io/term/terms-and-conditions',
    privacyPolicy: 'https://www.blockmate.io/term/end-user-privacy-policy',
    cookiePolicy: 'https://www.blockmate.io/term/cookie-policy',
    docsBlockmate: 'https://docs.blockmate.io',
    buildComponent:
      'https://docs.blockmate.io/docs/use-cases/aml-cft-address-risk-score/',
    learnComponent: 'https://docs.blockmate.io/docs/intro',
    quickStart: 'https://docs.blockmate.io/docs/quickstart',
  },
  debug: '/debug',
};

export default appRoutes;
