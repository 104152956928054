import { useEffect, useState } from 'react';
import axios from 'axios';
import { axiosErrorHandler, getStaticPath } from '../services/api/axios';
import { toast } from 'react-hot-toast';

const useLanguage = () => {
  const [language, setLanguage] = useState('sk');

  useEffect(() => {
    const getUsersLanguage = async () => {
      try {
        const response = await axios.get(getStaticPath('CLIENT_URL'));
        const languageFromResponse = response?.data?.language;
        if (!['sk', 'cs'].includes(languageFromResponse)) {
          toast.error(`Your language is set to ${languageFromResponse}, but the corresponding mutation of this document is not available. Falling back to Slovak.`, { duration: 6000 });
          setLanguage('sk');
        } else {
          setLanguage(languageFromResponse);
        }
      } catch (err) {
        toast.error(`Failed to get your language, falling back to Slovak. Error reason: ${axiosErrorHandler(err)}`);
      }
    };
    getUsersLanguage();
  }, []);

  return { language };
};


export default useLanguage;
