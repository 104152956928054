import CaspVaspTableRow from './CaspVaspTableRow';
import globalStyles from '../../pages/knowledge-hub/casp-vasp-list.module.scss';

const CaspVaspTableRowsSameCategory = ({ entries, category }) => {
  return <div>
    {entries.map((entry, index) => (
      <div key={`casp-vasp-entry-${category}-${index}`}>
        <CaspVaspTableRow {...entry} />
        {index < entries.length - 1 && entries[index + 1].register !== '' && <div className={globalStyles.rowDivider}></div>}
      </div>
    ))}
  </div>;
};

export default CaspVaspTableRowsSameCategory;
