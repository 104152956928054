import styles from '../customer-details/style.module.scss';
import { useOutletContext } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { axiosErrorHandler, getStaticPath } from '../../services/api/axios';
import { UserContext } from '../../context/user/UserContext';
import { ReactComponent as SearchIcon } from '../../assets/images/svg/search.svg';
import { ReactComponent as RefreshIcon } from '../../assets/images/svg/refresh.svg';
import Loader from '../../components/loaders/loader/Loader';
import ConnectedAccounts from '../customer-details/ConnectedAccounts';
import ConsolidatedTransactions from '../customer-details/ConsolidatedTransactions';
import { padWithZeros } from '../../utils/common';
import { toast } from 'react-hot-toast';
import { OLD_TABLES_KEY } from '../debug/debug';
import { clsx } from 'clsx';
import jwt_decode from 'jwt-decode';
import NoAccountMessage from '../../components/explorer-like/NoAccountMessage';
import { filterTypes } from '../../components/filters/ConsolidatedTransactionsFilters';
import AddressInput from '../../components/address-input/AddressInput';
import { getTronConnectedAccount } from '../../services/chain-data-loaders/chain-specific/tron/blockmate-api';
import getTronTxsLoader from '../../services/chain-data-loaders/chain-specific/tron/tronscan-api';
import useLoggerService, { operationTypes } from '../../services/operations-logger/logger-service';

const RefreshButton = ({ onClick }) => {
  return <div className='refresh-button' onClick={onClick}>
    <RefreshIcon className='icon' />
    <div className='refresh-text'>
      Refresh data
    </div>
  </div>;
};

const Explorer = () => {
  const { explorerProject } = useOutletContext();
  const { userEmail, getConfig, companyCurrency } = useContext(UserContext);

  const SUPPORTED_CHAINS = [
    {
      label: 'Bitcoin',
      ticker: 'btc',
      providerUrl: 'onchain/btc',
    },
    {
      label: 'Ethereum',
      ticker: 'eth',
      providerUrl: 'onchain/eth',
    }
  ].map(provider => ({ ...provider, external: false }));

  const HYBRIDLY_SUPPORTED_CHAINS = [
    {
      label: 'Tron',
      ticker: 'trx',
      explorerUrlBase: 'https://tronscan.org/#',
      externalProviderName: 'tronscan',
      getAddressPath: (address) => `/address/${address}`,
      enabled: true,
    },
  ].map(provider => ({ ...provider, external: false, hybrid: true }));

  const EXTERNALLY_SUPPORTED_CHAINS = [
    {
      label: 'Solana',
      ticker: 'sol',
      explorerUrlBase: 'https://solscan.io',
      externalProviderName: 'solscan',
      getAddressPath: (address) => `/account/${address}`,
      enabled: !getConfig('is_cs'),
    }
  ].map(provider => ({ ...provider, external: true }))
    .filter(provider => provider.enabled);

  const { logOperation } = useLoggerService();

  const [isLoading, setIsLoading] = useState(true);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [temporaryUserName, setTemporaryUserName] = useState();
  const [balanceData, setBalanceData] = useState();
  const [externalAccounts, setExternalAccounts] = useState([]);
  const [hybridAccountsTxsPageLoaders, setHybridAccountsTxsPageLoaders] = useState({});
  const [temporaryUserJwt, setTemporaryUserJwt] = useState(undefined);
  const [temporaryUserId, setTemporaryUserId] = useState(undefined);
  const [refreshTxsKey, setRefreshTxsKey] = useState(0);
  const oldTables = localStorage.getItem(OLD_TABLES_KEY) === 'ON';
  const [txsLoading, setTxsLoading] = useState(false);

  const allChains = [...SUPPORTED_CHAINS, ...EXTERNALLY_SUPPORTED_CHAINS, ...HYBRIDLY_SUPPORTED_CHAINS];
  const allChainTickers = allChains.map(chainInfo => chainInfo.ticker);

  const [connectedAddress, setConnectedAddress] = useState('');
  const [currentChain, setCurrentChain] = useState('btc');
  const [nextAccountIdByChain, setNextAccountIdByChain] = useState(
    Object.fromEntries(allChains.map(chainInfo => [chainInfo?.ticker, 1]))
  );

  const getTemporaryUserName = () => {
    if (temporaryUserName) {
      return temporaryUserName;
    }
    const userName = `${userEmail}-${Date.now()}`;
    setTemporaryUserName(userName);
    return userName;
  };

  const getTemporaryUserJwt = async (projectId, userName) => {
    try {
      const response = await axios.get(
        getStaticPath('APIAUTH_URL', `user/${projectId}/cluid`),
        {
          params: {
            cluid: encodeURIComponent(userName)
          }
        }
      );
      const jwt = response.data.token;
      setTemporaryUserJwt(jwt);
      const decoded = jwt_decode(jwt);
      setTemporaryUserId(decoded?.user_uuid);
      return jwt;
    } catch (error) {
      toast.error(axiosErrorHandler(error, 'Error getting a temporary auth token. Please try again later'));
    }
  };

  const connectAddress = async (projectId, chain, address, description) => {
    const userName = getTemporaryUserName();
    const userJwt = await getTemporaryUserJwt(projectId, userName);
    const chainInfo = SUPPORTED_CHAINS.find(providerInfo => providerInfo?.ticker === chain);
    if (!chainInfo) {
      console.error(`Chain ${JSON.stringify(chain)} is not supported.`);
    }
    try {
      await axios.post(
        getStaticPath('ACCOUNT_URL', 'connect'),
        {},
        {
          baseUrl: getConfig('api_url'),
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${userJwt}`,
            'content-type': 'application/json'
          },
          params: {
            user_jwt: userJwt,
            chain: chain.toLowerCase(),
            wallet: address,
            description: description ?? address
          }
        }
      );
      await fetchBalance(userJwt);
      setRefreshTxsKey(prevRefreshTxsKey => 1 - prevRefreshTxsKey);
      return true;
    } catch (error) {
      toast.error(axiosErrorHandler(error, 'Error connecting address'));
    }
    return false;
  };

  const fetchBalance = async (userJwt) => {
    try {
      let response = await axios.get(`/v1/aggregate/balance?currency=${companyCurrency ?? 'USD'}`,
        {
          baseURL: getConfig('api_url'),
          headers: {
            'Authorization': `Bearer ${userJwt}`,
          }
        });
      setBalanceData(prevBalanceData => {
        const hybridTickers = HYBRIDLY_SUPPORTED_CHAINS.map(chain => chain.ticker);
        const hybridAccountsBalanceData = (prevBalanceData?.accounts ?? [])
          .filter(acc => hybridTickers.includes(acc?.name));
        const updatedAccounts = [...(response?.data?.accounts ?? []), ...hybridAccountsBalanceData];
        if (updatedAccounts.length === 0) {
          return undefined;
        }
        return {
          balance_sum: prevBalanceData?.balance_sum ?? [],
          accounts: updatedAccounts,
        };
      });
    } catch (error) {
      setBalanceData(undefined);
      toast.error(axiosErrorHandler(error, 'Error getting balance data. Please try again later'));
    }
  };

  const handleConnect = async () => {
    if (!currentChain) {
      toast.error('The provided address does not belong to any of the supported chains.');
      return;
    }
    const provider = allChains.find(chainInfo => chainInfo?.ticker === currentChain);
    if (!provider) {
      toast.error(`Chain ${currentChain} is not supported.`);
      return;
    }

    const chainTicker = provider?.ticker;
    const accountId = nextAccountIdByChain[chainTicker];
    const description = `${chainTicker.toUpperCase()}-${padWithZeros(accountId, 2)}`;

    const updateAfterConnect = () => {
      setNextAccountIdByChain(prevNextAccountIdByChain => ({
        ...prevNextAccountIdByChain,
        [chainTicker]: accountId + 1
      }));
      setConnectedAddress('');
    };

    if (provider.external) {
      setExternalAccounts(prevAccounts => {
        if (prevAccounts.find(account => account.address === connectedAddress && account.chainTicker === currentChain)) {
          toast.error('Account already connected');
          return prevAccounts;
        }
        return [
          ...prevAccounts,
          {
            address: connectedAddress,
            chainTicker,
            description,
            externalUrl: `${provider.explorerUrlBase}${provider.getAddressPath(connectedAddress)}`,
            externalProviderName: provider.externalProviderName,
            providerLabel: provider.label,
          }
        ];
      });
      updateAfterConnect();
      return;
    }

    const newLoader = {};

    if (provider.hybrid) {
      const isAlreadyConnected = (balanceData?.accounts ?? []).filter(acc => acc.wallet === connectedAddress).length > 0;
      if (isAlreadyConnected) {
        toast.error('Account already connected');
        return;
      }
      const userName = getTemporaryUserName();
      const userJwt = await getTemporaryUserJwt(explorerProject.id, userName);
      const newlyConnectedAccount = await getTronConnectedAccount(
        connectedAddress, userJwt, getConfig('api_url'), description, companyCurrency
      );
      setBalanceData(prevBalanceData => ({
        balance_sum: prevBalanceData?.balance_sum,
        accounts: [
          ...(prevBalanceData?.accounts ?? []),
          newlyConnectedAccount,
        ]
      }));
      newLoader[description] = getTronTxsLoader({
        address: connectedAddress,
        accountId: description,
        currency: companyCurrency,
        apiUrl: getConfig('api_url'),
        userJwt: userJwt,
        externalApiKey: getConfig('tronscan_api_key'),
      });
      setRefreshTxsKey(prev => prev + 1);
    } else {
      const wasSuccessful = await connectAddress(explorerProject.id, chainTicker, connectedAddress, description);
    }
    setHybridAccountsTxsPageLoaders(prevLoaders => {
      const prevLoadersCopy = { ...prevLoaders };
      for (const loader of Object.values(prevLoadersCopy)) {
        loader.reset(); // Also reset all data sources to the start
      }
      return {
        ...prevLoadersCopy,
        ...newLoader, // Will be just {} in case provider.hybrid == false
      };
    });

    updateAfterConnect();

    await logOperation(operationTypes.explorer, explorerProject.id, chainTicker, connectedAddress);
  };

  const handleExternalAccountDisconnect = async (accountDescription) => {
    setExternalAccounts(prev => {
      const externalAccountsCopy = [...prev];
      return externalAccountsCopy.filter(account => account.description !== accountDescription);
    });
  };

  const handleHybridAccountDisconnect = (accountId) => {
    setBalanceData(prevBalanceData => {
      const allAccounts = prevBalanceData?.accounts ?? [];
      const remainingAccounts = allAccounts.filter(acc => acc.account_id !== accountId);
      if (remainingAccounts.length === 0) {
        return undefined;
      }
      return {
        ...prevBalanceData,
        accounts: remainingAccounts,
      };
    });
    setHybridAccountsTxsPageLoaders(prevLoaders => {
      const prevLoadersCopy = { ...prevLoaders };
      delete prevLoadersCopy[accountId];
      for (const loader of Object.values(prevLoadersCopy)) {
        loader.reset();
      }
      return prevLoadersCopy;
    });
    setRefreshTxsKey(prev => prev + 1);
  };

  const handleDisconnect = async (accountId) => {
    const isHybridAccount = accountId.length <= 7; // Not UUID
    if (isHybridAccount) {
      handleHybridAccountDisconnect(accountId);
      return;
    }

    const accountUrl = (balanceData?.accounts ?? []).find(account => account.account_id === accountId)?.url;
    if (!accountUrl) {
      return;
    }
    const userName = getTemporaryUserName();
    const userJwt = await getTemporaryUserJwt(explorerProject.id, userName);
    try {
      await axios.delete(
        `/v1/${accountUrl}`,
        {
          baseURL: getConfig('api_url'),
          headers: {
            'Authorization': `Bearer ${userJwt}`,
          }
        }
      );
      await fetchBalance(userJwt);
      // Also reset other loaders to their first pages
      setHybridAccountsTxsPageLoaders(prevLoaders => {
        for (const loader of Object.values(prevLoaders)) {
          loader.reset();
        }
        return prevLoaders;
      });
      setRefreshTxsKey(prevRefreshTxsKey => 1 - prevRefreshTxsKey);
    } catch (error) {
      toast.error(axiosErrorHandler(error, 'Error disconnecting account. Please try again'));
    }
  };

  const handleRefresh = async () => {
    try {
      setIsRefreshing(true);
      const userName = getTemporaryUserName();
      const userJwt = await getTemporaryUserJwt(explorerProject.id, userName);
      await fetchBalance(userJwt);
      setIsRefreshing(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (explorerProject) {
      setIsLoading(false);
    }
  }, [explorerProject]);

  if (isLoading) {
    return <Loader />;
  }

  return <div className='page-content-container'>
    <div className={clsx(styles.customers, 'explorer-container')}>

      <AddressInput
        connectedAddress={connectedAddress}
        setConnectedAddress={setConnectedAddress}
        currentChain={currentChain}
        setCurrentChain={setCurrentChain}
        handleConnect={handleConnect}
        chainFilter={allChainTickers}
        buttonLabel={balanceData ? 'ADD ACCOUNT' : 'EXPLORE ACCOUNT'}
      />

      {(!balanceData && (externalAccounts ?? []).length === 0) &&
        <div className='no-accounts-info'>
          <NoAccountMessage
            icon={<SearchIcon />}
            title='Explore accounts'
            description={
              'Please paste address above in the input field to search and explore crypto accounts. ' +
              'Account\'s chain will be automatically detected.'
            }
          />
        </div>
      }

      {(balanceData || (externalAccounts ?? []).length > 0) && <ConnectedAccounts
        balanceData={balanceData}
        externalAccounts={externalAccounts}
        reducedInfo
        button={isRefreshing ? <Loader relative /> : <RefreshButton onClick={handleRefresh} />}
        expandable={!oldTables}
        currency={companyCurrency ?? 'USD'}
        canDisconnect={!txsLoading}
        handleDisconnect={handleDisconnect}
        handleExternalAccountDisconnect={handleExternalAccountDisconnect}
      />}
      {balanceData && temporaryUserJwt && <ConsolidatedTransactions
        key={refreshTxsKey}
        customerName='explored-account'
        balanceData={balanceData}
        userJWT={temporaryUserJwt}
        userId={temporaryUserId}
        projectId={explorerProject?.id}
        concise={!oldTables}
        currency={companyCurrency ?? 'USD'}
        withFilters
        txsLoading={txsLoading}
        setTxsLoading={setTxsLoading}
        externalTxsPageLoaders={hybridAccountsTxsPageLoaders}
        useMisttrackData
        hiddenFilters={[filterTypes.RISKS]}
        detailedTypeProviders={['trx']}
      />}

    </div>
  </div>;
};

export default Explorer;
