import styles from './category-info.module.scss';
import { clsx } from 'clsx';
import { ReactComponent as PlusIcon } from '../../assets/images/svg/plus-icon.svg';
import { ReactComponent as MinusIcon } from '../../assets/images/svg/minus-icon.svg';
import { useContext, useEffect, useMemo, useState } from 'react';
import ConsolidatedTransactions from '../../pages/customer-details/ConsolidatedTransactions';
import jwt_decode from 'jwt-decode';
import { useOutletContext } from 'react-router-dom';
import { UserContext } from '../../context/user/UserContext';


const CategoryInfo = ({
  title,
  valueInfo,
  withBorder,
  chain,
  connectedAddress,
  temporaryUserJwt,
  thisCategoryMarkedAddresses = [],
  expandable = true,
  txsLoaderFactory,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { explorerProject } = useOutletContext();
  const { companyCurrency, getConfig } = useContext(UserContext);
  const [txsLoading, setTxsLoading] = useState(false);
  const [txsLoaders, setTxsLoaders] = useState({});
  const temporaryUserId = useMemo(() => {
    if (temporaryUserJwt !== undefined) {
      const decoded = jwt_decode(temporaryUserJwt);
      return decoded?.user_uuid;
    }
  }, [temporaryUserJwt]);
  const customAccountInfo = {
    address: connectedAddress,
    description: `${chain.toUpperCase()}-01`,
  };

  const toggleExpanded = () => {
    setIsExpanded(prevIsExpanded => {
      for (const loader of Object.values(txsLoaders)) {
        loader.reset();
      }
      return !prevIsExpanded;
    });
  };

  useEffect(() => {
    if (Object.keys(txsLoaders ?? {}).length === 0 && temporaryUserJwt) {
      const loaders = {};
      for (const otherAddress of thisCategoryMarkedAddresses) {
        let externalApiKey = undefined;
        if (chain.toLowerCase() === 'trx') {
          externalApiKey = getConfig('tronscan_api_key');
        }
        if (expandable) {
          const loaderParams = {
            address: connectedAddress,
            accountId: `${chain.toUpperCase()}-01`,
            currency: companyCurrency,
            apiUrl: getConfig('api_url'),
            userJwt: temporaryUserJwt,
            explorerProjectId: explorerProject?.id,
            blockmateApiUrl: getConfig('api_url'),
          };
          if (externalApiKey) {
            loaderParams.externalApiKey = externalApiKey;
          }
          if (otherAddress === connectedAddress) {
            const keyFrom = `${title}-${otherAddress}-1`;
            loaders[keyFrom] = txsLoaderFactory({
              ...loaderParams,
              fromAddress: connectedAddress,
              loaderKey: keyFrom,
            });
            const keyTo = `${title}-${otherAddress}-2`;
            loaders[keyTo] = txsLoaderFactory({
              ...loaderParams,
              toAddress: connectedAddress,
              loaderKey: keyTo,
            });
          } else {
            const keyFrom = `${title}-${otherAddress}-1`;
            loaders[keyFrom] = txsLoaderFactory({
              ...loaderParams,
              fromAddress: connectedAddress,
              toAddress: otherAddress,
              loaderKey: keyFrom,
            });
            const keyTo = `${title}-${otherAddress}-2`;
            loaders[keyTo] = txsLoaderFactory({
              ...loaderParams,
              fromAddress: otherAddress,
              toAddress: connectedAddress,
              loaderKey: keyTo,
            });
          }
        }
      }
      setTxsLoaders(loaders);
    }
  }, [temporaryUserJwt]);
  
  return <div className={styles.outerContainer}>
    <div className={clsx(styles.container, withBorder && styles.entryBorder)}>
      <div className={styles.innerContainer}>
        <div className={styles.leftPart}>
          <div className={clsx(styles.expander, expandable && styles.clickable)}>
            {expandable && !isExpanded && <PlusIcon onClick={toggleExpanded} />}
            {expandable && isExpanded && <MinusIcon onClick={toggleExpanded} />}
            {!expandable && <div></div>}
          </div>
          <div className={styles.title}>
            {title}
          </div>
        </div>
        <div className={styles.rightPart}>
          <div className={valueInfo.isSafe ? styles.safe : styles.unsafe}>
            {valueInfo.text}
          </div>
        </div>
      </div>
      {isExpanded && temporaryUserJwt && <ConsolidatedTransactions
        customerName='checked-account'
        balanceData={undefined}  // This is not needed, as we provide accountInfoOverride instead
        accountInfoOverride={customAccountInfo}
        userJWT={temporaryUserJwt}
        userId={temporaryUserId}
        projectId={explorerProject?.id}
        concise={true}
        currency={companyCurrency ?? 'USD'}
        withFilters={false}
        txsLoading={txsLoading}
        setTxsLoading={setTxsLoading}
        disableInternalLoader={true}
        externalTxsPageLoaders={txsLoaders}
        useMisttrackData={true}
        misttrackHighRiskOnly={true}
        detailedTypeProviders={['trx']}
        withHeader={false}
        disableBoxShadow={true}
      />}
    </div>
  </div>;
};

export default CategoryInfo;
