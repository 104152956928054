import styles from './casp-vasp-table-row.module.scss';
import { ReactComponent as PlusIcon } from '../../assets/images/svg/plus-icon.svg';
import { ReactComponent as MinusIcon } from '../../assets/images/svg/minus-icon.svg';
import { ReactComponent as InfoIcon } from '../../assets/images/svg/info-icon.svg';
import { ReactComponent as ExternalLinkIcon } from '../../assets/images/svg/external-link.svg';
import { clsx } from 'clsx';
import { useState } from 'react';
import { ellipsis, valueExists } from '../../utils/common';

const CaspVaspTableRow = ({ register, vasp, casp, transitionPeriod, details }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded(prevExpanded => !prevExpanded);
  };

  return <div className={styles.container}>
    <div className={styles.tableRow}>
      {valueExists(details) ?
        <div className={styles.centered}>
          <div
            className={clsx(styles.icon, styles.clickable)}
            onClick={toggleExpand}
          >
            {expanded ? <MinusIcon/> : <PlusIcon/>}
          </div>
        </div> :
        <div></div>
      }
      <div className={styles.centered}>
        <div>
          {register}
        </div>
      </div>
      <div className={styles.centered}>
        {valueExists(vasp) && <a className={styles.linkContainer} href={vasp} target='_blank' rel='noreferrer'>
          <div className={styles.icon}>
            <ExternalLinkIcon/>
          </div>
          {ellipsis(vasp, 24)}
        </a>}
      </div>
      <div className={styles.centered}>
        {valueExists(casp) && <a className={styles.linkContainer} href={casp} target='_blank' rel='noreferrer'>
          <div className={styles.icon}>
            <ExternalLinkIcon/>
          </div>
          {ellipsis(casp, 24)}
        </a>}
      </div>
      <div className={styles.centered}>
        <div>
          {transitionPeriod}
        </div>
      </div>
    </div>
    {expanded && <div className={styles.expandedSubrowContainer}>
      <div className={styles.infoIcon}>
        <InfoIcon />
      </div>
      <div className={styles.expandedSubrowContentContainer}>
        {details}
      </div>
    </div>}
  </div>;
};

export default CaspVaspTableRow;
