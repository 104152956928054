import axios from 'axios';
import { axiosErrorHandler, getStaticPath } from '../../../api/axios';
import jwt_decode from 'jwt-decode';
import { toast } from 'react-hot-toast';
import { valueExists } from '../../../../utils/common';
import { addMisttrackRiskToTxs } from '../../../../utils/misttrack';
import { handleQuickRiskError } from '../../../../utils/quick-risk';
import { filterTypes } from '../../../../components/filters/ConsolidatedTransactionsFilters';

export const getBtcEthTxsLoader = ({
  address,
  accountId,
  withMisttrackRisks = true,
  currency = 'usd',
  pageSize = 50,
  apiUrl,
  userJwt,
  fromAddress = undefined,
  toAddress = undefined,
  externalApiKey = undefined,
  blockmateApiUrl = undefined,
  explorerProjectId = undefined,
  loaderKey,
}) => {
  const LAST_PAGE_CURSOR = '';

  let cursor = undefined;
  let nextPageExists = true;
  let temporaryUserName = `${explorerProjectId}-${Date.now()}-${loaderKey}`;
  let temporaryJwt = undefined;
  let temporaryUserId = undefined;
  let connectedAccountId = undefined;
  let addressConnected = false;
  let chain = address.startsWith('0x') ? 'eth' : 'btc';

  const updateTemporaryUserData = async () => {
    const temporaryUserData = await getTemporaryUserData();
    temporaryJwt = temporaryUserData?.temporaryUserJwt;
    temporaryUserId = temporaryUserData?.temporaryUserJwt;
  };

  setInterval(updateTemporaryUserData, 1000 * 60 * 5);

  const getTemporaryUserData = async () => {
    try {
      const response = await axios.get(
        getStaticPath('APIAUTH_URL', `user/${explorerProjectId}/cluid`),
        {
          params: {
            cluid: encodeURIComponent(temporaryUserName)
          }
        }
      );
      const jwt = response.data.token;
      const decoded = jwt_decode(jwt);
      return {
        temporaryUserJwt: jwt,
        temporaryUserUuid: decoded?.user_uuid
      };
    } catch (error) {
      toast.error(axiosErrorHandler(error, 'Error getting a temporary auth token. This is an error on our side. Please try again in a minute.'));
    }
  };

  const connectAddress = async () => {
    if (addressConnected || connectedAccountId !== undefined) {
      return true;
    }
    try {
      if (temporaryJwt === undefined) {
        await updateTemporaryUserData();
      }
      addressConnected = true;
      const accountInfo = await axios.post(
        getStaticPath('ACCOUNT_URL', 'connect'),
        {},
        {
          baseUrl: blockmateApiUrl,
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${temporaryJwt}`,
            'content-type': 'application/json'
          },
          params: {
            user_jwt: temporaryJwt,
            chain: chain,
            wallet: address,
            description: address,
          }
        }
      );
      connectedAccountId = accountInfo?.data?.id;
      return true;
    } catch (error) {
      console.error('Duplicit connect');
    }
    return false;
  };

  const loadNextPage = async (filters) => {
    await connectAddress(); // Does nothing if already connected
    if (connectedAccountId === undefined) {
      return {
        nextPage: [],
        loadError: undefined,
      };
    }

    const params = {
      currency: currency.toUpperCase(),
      risk2: 0,  // Only needed parts of risk2 are loaded separately
    };

    if (cursor) {
      params.cursor = cursor;
    }
    if (fromAddress) {
      params['from-address'] = fromAddress;
    }
    if (toAddress) {
      params['to-address'] = toAddress;
    }

    if (filters) {
      const {
        accountsFilterParam,
        digitalAssetsFilterParam,
      } = filters;

      if (valueExists(accountsFilterParam)) {
        if (!accountsFilterParam.split(',').includes(accountId)) {
          nextPageExists = false;
          return {
            nextPage: [],
            loadError: undefined,
          };
        }
      }
      if (valueExists(digitalAssetsFilterParam)) {
        if (!digitalAssetsFilterParam.split(',').includes(chain.toUpperCase())) {
          nextPageExists = false;
          return {
            nextPage: [],
            loadError: undefined,
          };
        }
      }
    }

    let data = [];
    let latestCursor;
    try {
      const response = await axios.get(
        `${blockmateApiUrl}/v1/aggregate/transactions`,
        {
          params,
          headers: {
            'Authorization': `Bearer ${temporaryJwt}`,
          },
        }
      );
      data = response?.data?.transactions ?? [];
      latestCursor = response?.data?.page_cursor;
    } catch (err) {
      toast.error(
        axiosErrorHandler(
          err,
          `Error getting ${chain.toUpperCase()} transactions from explorer, data might be incomplete`
        )
      );
    }

    cursor = latestCursor;
    if (cursor === LAST_PAGE_CURSOR) {
      nextPageExists = false;
    }
    const accountTransactions = [...data];

    if (!withMisttrackRisks) {
      return {
        nextPage: accountTransactions,
        loadError: undefined,
      };
    }

    try {
      const quickRiskResponse = await axios.get(
        '/v1/quick_risk',
        {
          baseURL: apiUrl,
          headers: {
            'Authorization': `Bearer ${temporaryJwt}`,
          },
          params: {
            chain: chain,
            address: address,
            metrics: 'riskResult',
          },
        }
      );
      const riskDetail = quickRiskResponse?.data?.risk_result?.risk_detail ?? [];
      const txsWithCustomRisk = addMisttrackRiskToTxs(accountTransactions, riskDetail);
      return {
        nextPage: txsWithCustomRisk,
        loadError: undefined,
      };
    } catch (err) {
      handleQuickRiskError(err);
      return {
        nextPage: [],
        loadError: undefined,
      };
    }
  };

  const hasNextPage = () => {
    return nextPageExists;
  };

  const reset = () => {
    cursor = undefined;
    nextPageExists = true;
  };

  const getAllowedFilters = () => {
    return [filterTypes.ACCOUNTS, filterTypes.ASSETS];
  };

  return { loadNextPage, hasNextPage, reset, getAllowedFilters };
};
