import styles from './proof-of-funds.module.scss';
import Header from '../../components/header/Header';
import routes from '../../routes/routes';
import useLanguage from '../../hooks/language';

const ProofOfFunds = () => {
  const { language } = useLanguage();

  return <div className='page-content-container'>
    <Header headerTitle='Knowledge hub / Proof of funds' withBackButton backRoute={routes.home.knowledgeHub.dashboard} />
    <div className={styles.outerContainer}>
      {language === 'sk' && <div className={styles.container}>
        <p>Doklady o finančných prostriedkoch/majetku (PoF/PoW) preukazujúci pôvod finančných prostriedkov/majetku.</p>

        <p>
          <b>Zákazník (Individual) zasiela:</b><br/>
          Dokumentáciu o pôvode všetkých jeho prostriedkov v kryptomenách, ktorá zahŕňa vlastníctvo jeho účtu a históriu transakcií alebo všetky príslušné potvrdenia o nákupe, výbere alebo výmene.
        </p>

        <div>
          <p>Niekoľko príkladov možných zdrojov prostriedkov v kryptomenách s akceptovanými dokumentami v jednotlivých kategóriách:</p>
          <ol type='1'>
            <li>
              <div>Kryptomeny zakúpené na burzách, brokeroch alebo obchodných platformách: </div>
              <ol type='a'>
                <li>
                  Screenshot alebo výpis z účtu s potvrdením o nákupe a výbere, na ktorých je uvedené ID účtu alebo iný jedinečný identifikátor a všetky podrobnosti o transakcii <b>ALEBO</b>
                </li>
                <li>
                  Účtenky, ktoré poskytujú dôkaz o nákupe (napr. pomocou mimoburzového obchodu) <b>A</b>
                </li>
                <li>
                  Screenshot alebo výpis z účtu burzy zobrazujúce podrobnosti o účte, a meno vlastníka účtu
                </li>
              </ol>
            </li>

            <li>
              <div>Zisky z obchodovania na burzách, brokeroch alebo obchodných platformách</div>
              <ol type='a'>
                <li>
                  Screenshot alebo výpis z účtu s úplnou históriou transakcií zobrazujúce zisky dosiahnuté na burzách, brokeroch alebo obchodných platformách a zobrazujúce výbery smerujúce na účet zákazníka v Slovenskej Sporiteľni <b>A</b>
                </li>
                <li>
                  Screenshot alebo výpis z účtu burzy zobrazujúce podrobnosti o účte, a meno vlastníka účtu
                </li>
              </ol>
            </li>

            <li>
              <div>Ťažba kryptomien (Mining)</div>
              <ol type='a'>
                <li>
                  Doklad o vlastníctve zariadení použitých pri ťažbe (napr. fotografie, účty za nákup) <b>A</b>
                </li>
                <li>
                  Screenshot ťažobnej platformy zobrazujúce odmeny, hashrate a počet ťažiarov
                </li>
                <li>
                  Screenshot alebo e-mailové potvrdenia zobrazujúce históriu transakcií z peňaženiek a do peňaženiek so zobrazením adries peňaženiek a hashov transakcií
                </li>
              </ol>
            </li>

            <li>
              <div>Staking (Master Nodes) </div>
              <ol type='a'>
                <li>
                  Screenshot histórie transakcií, na ktorých sú viditeľné vklady, výbery a zisky vrátane úrokovej sadzby a obdobia stakingu <b>A</b>
                </li>
                <li>
                  Adresy peňaženiek a ID transakcií
                </li>
              </ol>
            </li>

            <li>
              <div>Airdrops</div>
              <ol type='a'>
                <li>
                  Screenshot histórie transakcií, na ktorých sú viditeľné vklady, výbery a zisky vrátane úrokovej sadzby a obdobia stávkovania <b>A</b>
                </li>
                <li>
                  Adresy peňaženiek a ID transakcií
                </li>
              </ol>
            </li>

            <li>
              <div>Zámena mincí alebo tokenov (Swap)</div>
              <ol type='a'>
                <li>
                  Screenshot histórie transakcií (adresy peňaženiek a ID transakcií), na ktorých sú zobrazené výmeny a výbery smerujúce na účet zákazníka v Slovenskej Sporiteľni
                </li>
              </ol>
            </li>

            <li>
              <div>Pôžičkové fondy a fondy likvidity (Lending a Liquidity pools) </div>
              <ol type='a'>
                <li>
                  Screenshot obrazovky s históriou transakcií (adresy peňaženiek a ID transakcií), na ktorých je jasne uvedený veriteľ/skupina likvidity, suma a pôvod vloženého kolaterálu
                </li>
              </ol>
            </li>

            <li>
              <div>Prvotná ponuka mincí (ICO) a predaj tokenov</div>
              <ol type='a'>
                <li>
                  Pre predaj tokenov: Podpísaná zmluva potvrdzujúca platbu v kryptomene
                </li>
                <li>
                  V prípade ICO: Podpísaná zmluva vrátane úplného názvu ICO
                </li>
                <li>
                  História transakcií (screenshot/e-mail) dokumentujúca nákup a výber tokenu (adresy peňaženiek a ID transakcie)
                </li>
                <li>
                  Výpis z bankového účtu, ak sa na investovanie použil fiat
                </li>
              </ol>
            </li>

            <li>
              <div>Príjem zo služieb</div>
              <ol type='a'>
                <li>
                  Faktúry za služby, ktoré boli zaplatené v kryptomenách <b>A</b>
                </li>
                <li>
                  Screenshot alebo výpis z účtu s úplnou históriou transakcií zobrazujúce zisky dosiahnuté na burzách, brokeroch alebo obchodných platformách a zobrazujúce výbery smerujúce na učet zákazníka v Slovenskej Sporiteľni <b>A</b>
                </li>
                <li>
                  Screenshot alebo výpis z účtu burzy zobrazujúce podrobnosti o účte, a meno vlastníka účtu
                </li>
              </ol>
            </li>
          </ol>
        </div>

        <br/>

        <p>
          <b>Zákazník (Company) zasiela:</b><br/>
          Dokumentáciu o pôvode všetkých jeho prostriedkov v kryptomenách, ktorá zahŕňa vlastníctvo jeho účtu a históriu transakcií alebo všetky príslušné potvrdenia o nákupe, výbere alebo výmene.
        </p>

        <div>
          <p>Niekoľko príkladov možných zdrojov prostriedkov v kryptomenách s akceptovanými dokumentami v jednotlivých kategóriách:</p>
          <ol type='1'>

            <li>
              <div>Kryptomeny zakúpené na burzách, brokeroch alebo obchodných platformách:</div>
              <ol type='a'>
                <li>
                  Screenshot alebo výpis z účtu s potvrdením o nákupe a výbere, na ktorých je uvedené ID účtu alebo iný jedinečný identifikátor a všetky podrobnosti o transakcii <b>ALEBO</b>
                </li>
                <li>
                  Účtenky, ktoré poskytujú dôkaz o nákupe (napr. pomocou mimoburzového obchodu) <b>A</b>
                </li>
                <li>
                  Screenshot alebo výpis z účtu burzy zobrazujúce podrobnosti o účte, a meno vlastníka účtu
                </li>
              </ol>
            </li>

            <li>
              <div>Zisky z obchodovania na burzách, brokeroch alebo obchodných platformách </div>
              <ol type='a'>
                <li>
                  Screenshot alebo výpis z účtu s úplnou históriou transakcií zobrazujúce zisky dosiahnuté na burzách, brokeroch alebo obchodných platformách a zobrazujúce výbery smerujúce na účet zákazníka v Slovenskej Sporiteľni <b>A</b>
                </li>
                <li>
                  Screenshot alebo výpis z účtu burzy zobrazujúce podrobnosti o účte, a meno vlastníka účtu
                </li>
              </ol>
            </li>

            <li>
              <div>Ťažba kryptomien (Mining)</div>
              <ol type='a'>
                <li>
                  Doklad o vlastníctve zariadení použitých pri ťažbe (napr. fotografie, účty za nákup) <b>A</b>
                </li>
                <li>
                  Účtenky za nákup ťažobného hardvéru s uvedením adresy kupujúceho a predávajúceho. Hardvér musí byť zaslaný na adresu podniku, ktorá je vlastníkom účtu v Slovenskej Sporiteľni na ktorú smerovali prostriedky z transakcie a pre ktorý predkladáte doklad. Ak sa táto adresa medzičasom zmenila, preukážte, že podnik bol v tom čase registrovaný na tejto adrese
                </li>
                <li>
                  Fotografie ťažobného hardvéru a screenshot používaných platforiem a programov
                </li>
                <li>
                  Výpisy prevádzkových nákladov (napr. účty za elektrinu)
                </li>
                <li>
                  Screenshot o príjmoch z ťažby a odmenách
                </li>
                <li>
                  Screenshot procesu transakcií z a do súkromných a firemných peňaženiek
                </li>
                <li>
                  Ak sa obchodovalo aj s mincami z ťažby, je potrebné, aby ste poskytli screenshoty s históriou transakcií, na ktorých je jasne zobrazený príjem na príslušnej burze a meno majiteľa účtu.
                </li>
                <li>
                  Screenshot alebo e-mailové potvrdenia zobrazujúce históriu transakcií z peňaženiek a do peňaženiek so zobrazením adries peňaženiek a hashov transakcií
                </li>
              </ol>
            </li>

            <li>
              <div>Staking (Master Nodes)</div>
              <ol type='a'>
                <li>
                  Screenshot histórie transakcií, na ktorých sú viditeľné vklady, výbery a zisky vrátane úrokovej sadzby a obdobia stakingu <b>A</b>
                </li>
                <li>
                  Adresy peňaženiek a ID transakcií
                </li>
              </ol>
            </li>

            <li>
              <div>Airdrops</div>
              <ol type='a'>
                <li>
                  Screenshot histórie transakcií, na ktorých sú viditeľné vklady, výbery a zisky vrátane úrokovej sadzby a obdobia stávkovania <b>A</b>
                </li>
                <li>
                  Adresy peňaženiek a ID transakcií
                </li>
              </ol>
            </li>

            <li>
              <div>Zámena mincí alebo tokenov (Swap)</div>
              <ol type='a'>
                <li>
                  Screenshot histórie transakcií (adresy peňaženiek a ID transakcií), na ktorých sú zobrazené výmeny a výbery smerujúce na účet zákazníka v Slovenskej Sporiteľni
                </li>
              </ol>
            </li>

            <li>
              <div>Pôžičkové fondy a fondy likvidity (Lending a Liquidity pools)</div>
              <ol type='a'>
                <li>
                  Screenshot obrazovky s históriou transakcií (adresy peňaženiek a ID transakcií), na ktorých je jasne uvedený veriteľ/skupina likvidity, suma a pôvod vloženého kolaterálu
                </li>
              </ol>
            </li>

            <li>
              <div>Prvotná ponuka mincí (ICO) a predaj tokenov</div>
              <ol type='a'>
                <li>
                  Pre predaj tokenov: Podpísaná zmluva potvrdzujúca platbu v kryptomene
                </li>
                <li>
                  V prípade ICO: Podpísaná zmluva vrátane úplného názvu ICO
                </li>
                <li>
                  História transakcií (screenshot/e-mail) dokumentujúca nákup a výber tokenu (adresy peňaženiek a ID transakcie)
                </li>
                <li>
                  Výpis z bankového účtu, ak sa na investovanie použil fiat
                </li>
              </ol>
            </li>

            <li>
              <div>Príjem zo služieb </div>
              <ol type='a'>
                <li>
                  Faktúry za služby, ktoré boli zaplatené v kryptomenách s názvom spoločnosti odosielateľa a príjemcu, prípadne ich IČ DPH <b>A</b>
                </li>
                <li>
                  Screenshot alebo výpis z účtu s úplnou históriou transakcií zobrazujúce zisky dosiahnuté na burzách, brokeroch alebo obchodných platformách a zobrazujúce výbery smerujúce na účet zákazníka v Slovenskej Sporiteľni <b>A</b>
                </li>
                <li>
                  Screenshot alebo výpis z účtu burzy zobrazujúce podrobnosti o účte, a meno vlastníka účtu
                </li>
              </ol>
            </li>

          </ol>
        </div>
      </div>}




      {language === 'cs' && <div className={styles.container}>
        <p>Doklady o finančních prostředcích/majetku (PoF/PoW) prokazující původ finančních prostředků/majetku.</p>

        <p>
          <b>Zákazník (Individual) zasílá:</b><br/>
          Dokumentaci o původu všech jeho prostředků v kryptoměnách, která zahrnuje vlastnictví jeho účtu a historii
          transakcí nebo všechna příslušná potvrzení o nákupu, výběru nebo směně.
        </p>

        <div>
          <p>Několik příkladů možných zdrojů prostředků v kryptoměnách s akceptovanými dokumenty v jednotlivých
            kategoriích:</p>
          <ol type='1'>
            <li>
              <div>Kryptoměny zakoupené na burzách, brokerech, nebo na obchodních platformách:</div>
              <ol type='a'>
                <li>
                  Screenshot nebo výpis z účtu s potvrzením o nákupu a výběru, na kterých je uvedeno ID účtu nebo jiný
                  jedinečný identifikátor a všechny podrobnosti o transakci <b>NEBO</b>
                </li>
                <li>
                  Účtenky, které poskytují důkaz o nákupu (např. pomocí mimoburzovního obchodu) <b>A</b>
                </li>
                <li>
                  Screenshot nebo výpis z účtu burzy zobrazující podrobnosti o účtu a jméno vlastníka účtu
                </li>
              </ol>
            </li>

            <li>
              <div>Zisky z obchodování na burzách, brokerech nebo obchodních platformách</div>
              <ol type='a'>
                <li>
                  Screenshot nebo výpis z účtu s úplnou historií transakcí zobrazující zisky dosažené na burzách,
                  brokerech nebo obchodních platformách a zobrazující výběry směřující na účet zákazníka v České
                  Spořitelně <b>A</b>
                </li>
                <li>
                  Screenshot nebo výpis z účtu burzy zobrazující podrobnosti o účtu a jméno vlastníka účtu
                </li>
              </ol>
            </li>

            <li>
              <div>Těžba kryptoměn (Mining)</div>
              <ol type='a'>
                <li>
                  Doklad o vlastnictví zařízení použitých při těžbě (např. fotografie, účty za nákup) <b>A</b>
                </li>
                <li>
                  Screenshot těžební platformy zobrazující odměny, hashrate a počet těžařů
                </li>
                <li>
                  Screenshot nebo e-mailová potvrzení zobrazující historii transakcí z peněženek a do peněženek se
                  zobrazením adres peněženek a hashů transakcí
                </li>
              </ol>
            </li>

            <li>
              <div>Staking (Master Nodes)</div>
              <ol type='a'>
                <li>
                  Screenshot historie transakcí, na kterých jsou viditelné vklady, výběry a zisky včetně úrokové sazby a
                  období stakingu <b>A</b>
                </li>
                <li>
                  Adresy peněženek a ID transakcí
                </li>
              </ol>
            </li>

            <li>
              <div>Airdrops</div>
              <ol type='a'>
                <li>
                  Screenshot historie transakcí, na kterých jsou viditelné vklady, výběry a zisky včetně úrokové sazby a
                  období sázkování <b>A</b>
                </li>
                <li>
                  Adresy peněženek a ID transakcí
                </li>
              </ol>
            </li>

            <li>
              <div>Směna mincí nebo tokenů (Swap)</div>
              <ol type='a'>
                <li>
                  Screenshot historie transakcí (adresy peněženek a ID transakcí), na kterých jsou zobrazeny směny a
                  výběry směřující na účet zákazníka v České Spořitelně
                </li>
              </ol>
            </li>

            <li>
              <div>Půjčkové fondy a fondy likvidity (Lending a Liquidity pools)</div>
              <ol type='a'>
                <li>
                  Screenshot obrazovky s historií transakcí (adresy peněženek a ID transakcí), na kterých je jasně
                  uveden věřitel/skupina likvidity, částka a původ vloženého kolaterálu
                </li>
              </ol>
            </li>

            <li>
              <div>Prvotní nabídka mincí (ICO) a prodej tokenů</div>
              <ol type='a'>
                <li>
                  Pro prodej tokenů: Podepsaná smlouva potvrzující platbu v kryptoměně
                </li>
                <li>
                  V případě ICO: Podepsaná smlouva včetně úplného názvu ICO
                </li>
                <li>
                  Historie transakcí (screenshot/e-mail) dokumentující nákup a výběr tokenu (adresy peněženek a ID
                  transakce)
                </li>
                <li>
                  Výpis z bankovního účtu, pokud se k investování použil fiat
                </li>
              </ol>
            </li>

            <li>
              <div>Příjem ze služeb</div>
              <ol type='a'>
                <li>
                  Faktury za služby, které byly zaplaceny v kryptoměnách <b>A</b>
                </li>
                <li>
                  Screenshot nebo výpis z účtu s úplnou historií transakcí zobrazující zisky dosažené na burzách,
                  brokerech nebo obchodních platformách a zobrazující výběry směřující na účet zákazníka v České
                  Spořitelně <b>A</b>
                </li>
                <li>
                  Screenshot nebo výpis z účtu burzy zobrazující podrobnosti o účtu a jméno vlastníka účtu
                </li>
              </ol>
            </li>
          </ol>
        </div>

        <br/>

        <p>
          <b>Zákazník (Company) zasílá:</b><br/>
          Dokumentaci o původu všech jeho prostředků v kryptoměnách, která zahrnuje vlastnictví jeho účtu a historii
          transakcí nebo všechna příslušná potvrzení o nákupu, výběru nebo směně.
        </p>

        <div>
          <p>Několik příkladů možných zdrojů prostředků v kryptoměnách s akceptovanými dokumenty v jednotlivých
            kategoriích:</p>
          <ol type='1'>

            <li>
              <div>Kryptoměny zakoupené na burzách, brokerech nebo obchodních platformách:</div>
              <ol type='a'>
                <li>
                  Screenshot nebo výpis z účtu s potvrzením o nákupu a výběru, na kterých je uvedeno ID účtu nebo jiný
                  jedinečný identifikátor a všechny podrobnosti o transakci <b>NEBO</b>
                </li>
                <li>
                  Účtenky, které poskytují důkaz o nákupu (např. pomocí mimoburzovního obchodu) <b>A</b>
                </li>
                <li>
                  Screenshot nebo výpis z účtu burzy zobrazující podrobnosti o účtu a jméno vlastníka účtu
                </li>
              </ol>
            </li>

            <li>
              <div>Zisky z obchodování na burzách, brokerech nebo obchodních platformách</div>
              <ol type='a'>
                <li>
                  Screenshot nebo výpis z účtu s úplnou historií transakcí zobrazující zisky dosažené na burzách,
                  brokerech nebo obchodních platformách a zobrazující výběry směřující na účet zákazníka v České
                  Spořitelně <b>A</b>
                </li>
                <li>
                  Screenshot nebo výpis z účtu burzy zobrazující podrobnosti o účtu a jméno vlastníka účtu
                </li>
              </ol>
            </li>

            <li>
              <div>Těžba kryptoměn (Mining):</div>
              <ol type='a'>
                <li>
                  Doklad o vlastnictví zařízení používaných k těžbě (například fotografie, faktury za nákup) <b>NEBO</b>
                </li>
                <li>
                  Faktury za nákup těžebního hardwaru, které obsahují adresu kupujícího a prodávajícího. Hardware musí
                  být dodán na adresu společnosti, která vlastní účet v České Spořitelně, na který byly prostředky z
                  transakce připsány. Pokud se tato adresa změnila, je třeba doložit, že společnost byla v té době
                  registrována na této adrese
                </li>
                <li>
                  Fotografie těžebního hardwaru a screenshot používaných těžebních platforem a programů
                </li>
                <li>
                  Výpis provozních nákladů (například účty za elektřinu)
                </li>
                <li>
                  Screenshot příjmů z těžby a odměn
                </li>
                <li>
                  Screenshot procesů transakcí z a do soukromých a firemních peněženek
                </li>
                <li>
                  Pokud byly těžené mince obchodovány, je nutné dodat screenshoty historie transakcí, kde je jasně vidět
                  příjem na burze a jméno majitele účtu
                </li>
                <li>
                  Screenshot nebo e-mailová potvrzení zobrazující historii transakcí z peněženek a do peněženek s
                  uvedením adres peněženek a hashů transakcí
                </li>
              </ol>
            </li>

            <li>
              <div>Staking (Master Nodes)</div>
              <ol type='a'>
                <li>
                  Screenshot historie transakcí, na kterých jsou viditelné vklady, výběry a zisky, včetně úrokové sazby
                  a období stakingu <b>NEBO</b>
                </li>
                <li>
                  Adresy peněženek a ID transakcí
                </li>
              </ol>
            </li>

            <li>
              <div>Airdrops</div>
              <ol type='a'>
                <li>
                  Screenshot historie transakcí, na kterých jsou viditelné vklady, výběry a zisky, včetně úrokové sazby
                  a období stakingu <b>NEBO</b>
                </li>
                <li>
                  Adresy peněženek a ID transakcí
                </li>
              </ol>
            </li>

            <li>
              <div>Výmena mincí alebo tokenů (Swap)</div>
              <ol type='a'>
                <li>
                  Screenshot historie transakcí (adresy peněženek a ID transakcí), na kterých jsou zobrazeny výměny a
                  výběry směřující na účet zákazníka v České Spořitelně
                </li>
              </ol>
            </li>

            <li>
              <div>Půjčkové fondy a fondy likvidity (Lending a Liquidity pools)</div>
              <ol type='a'>
                <li>
                  Screenshot obrazovky s historií transakcí (adresy peněženek a ID transakcí), na kterých je jasně
                  uveden věřitel/skupina likvidity, částka a původ vloženého kolaterálu
                </li>
              </ol>
            </li>

            <li>
              <div>Prvotní nabídka mincí (ICO) a prodej tokenů</div>
              <ol type='a'>
                <li>
                  Pro prodej tokenů: Podepsaná smlouva potvrzující platbu v kryptoměně
                </li>
                <li>
                  V případě ICO: Podepsaná smlouva včetně úplného názvu ICO
                </li>
                <li>
                  Historie transakcí (screenshot/e-mail) dokumentující nákup a výběr tokenu (adresy peněženek a ID
                  transakce)
                </li>
                <li>
                  Výpis z bankovního účtu, pokud byl k investici použit fiat
                </li>
              </ol>
            </li>

            <li>
              <div>Příjem ze služeb</div>
              <ol type='a'>
                <li>
                  Faktury za služby, které byly zaplaceny v kryptoměnách, včetně názvu společnosti odesílatele a
                  příjemce, případně jejich DIČ <b>NEBO</b>
                </li>
                <li>
                  Screenshot nebo výpis z účtu s úplnou historií transakcí zobrazující zisky dosažené na burzách,
                  brokerech nebo obchodních platformách a zobrazující výběry směřující na účet zákazníka v České
                  Spořitelně <b>NEBO</b>
                </li>
                <li>
                  Screenshot nebo výpis z účtu burzy zobrazující podrobnosti o účtu a jméno vlastníka účtu
                </li>
              </ol>
            </li>

          </ol>
        </div>
      </div>}
    </div>
  </div>;
};

export default ProofOfFunds;
