import axios from 'axios';
import { toast } from 'react-hot-toast';
import { axiosErrorHandler, getStaticPath } from '../api/axios';

export const operationTypes = {
  quickCheck: 'QUICK_CHECK',
  explorer: 'EXPLORER',
};

const useLoggerService = () => {

  const logOperation = async (recordType, projectId, chain, address) => {
    try {
      const response = await axios.post(
        getStaticPath('OPERATION_URL', 'log'),
        {
          record_type: recordType,
          project_id: projectId,
          chain,
          address,
        },
      );
      return response?.data;
    } catch (err) {
      toast.error(axiosErrorHandler(err));
      return [];
    }
  };

  return {
    logOperation
  };
};

export default useLoggerService;
